import fullsizeImagesUI from './fullsize';
import slidersUI from './sliders';
import mapsUI from './maps-ui';

;(function(window, document, $) {
	$('a[href^="tel"]').each(function() {
		const linkHref = $(this).attr('href');

		$(this).attr('href', linkHref.replace(/ /igm, ''));
	});

	$('.field-upload').each(function(index, el) {
		$(el).on('change', function(event) {
			const fieldValue = event.target.files[0].name;

			$(this).find('.field-upload__text').text(fieldValue);
			console.log($(this))
		});
	});

	fullsizeImagesUI();

	slidersUI();

	mapsUI();

	$('.js-nav-btn').on('click', function(event) {
		event.preventDefault();
		
		$('.js-nav-btn').toggleClass('active');
		$('.header .header__content').slideToggle();
	});

	const $dropdown = $('.dropdown');
	const $dropdownLink = $('.nav-mobile .has-dropdown > a');
	const $navBtn = $('.nav-mobile > ul > li > a');
	const $navBtnParent = $('.nav-mobile > ul > li');
	const $navBtnParentDesktop = $('.nav > ul > li');

	$dropdownLink.on('click', function(event){

		var $link = $(this);

		if ( $(window).width() <= 1024 ){
			if ( !$link.hasClass('clicked') ) {
				event.preventDefault();

				$link.addClass('clicked');

				$link
					.siblings('.dropdown')
						.slideToggle()
					.parent()
					.siblings()
						.find('.dropdown')
							.slideUp()
						.siblings()
							.removeClass('clicked')
				}
			}
	});

	$(window).on('load resize', function() {
		if ($(window).width() > 1024) {
			$navBtnParentDesktop.addClass('hover');
			$('.nav').removeAttr('style');
			$dropdown.removeAttr('style');
			$dropdownLink.removeClass('clicked');
		} else {
			$navBtnParentDesktop.removeClass('hover');
		}
	});

	$('.slider-for').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: false,
	  asNavFor: '.slider-nav',
	  infinite: false,
	});
	
	$('.slider-nav').slick({
	  slidesToShow: 3,
	  slidesToScroll: 1,
	  asNavFor: '.slider-for',
	  focusOnSelect: true,
	  infinite: false,
	});

	$('.js-toggle-search').on('click', function(event) {
		event.preventDefault();
		
		$('.search-bar').slideToggle();
	});
})(window, document, window.jQuery);
