import { $win, getCurrentDevice } from './utils';

const initMap = () => {
	const $mapContainers = $('.map');

	if ($mapContainers.length) {
		$mapContainers.each((index, el) => {
			const $mapContainer = $(el);
			const $map = $mapContainer.find('.map__iframe');
			const mapLat = $map.data('lat') || 0;
			const mapLng = $map.data('lng') || 0;
			const zoom = $map.data('zoom') || 17;
			const $infoboxes = $mapContainer.find('.map__infobox');
			let markers = [];

			let selectedInfoWindow;

			const bounds = new google.maps.LatLngBounds();

			// Map Options
			const mapOptions = {
				zoom,
				center: new google.maps.LatLng(mapLat, mapLng),
				disableDefaultUI: true
			};

			const map = new google.maps.Map($map[0], mapOptions);

			// Handle Infobox
			$infoboxes.each((index, el) => {
				const $this = $(el);
				const lat = $this.data('lat');
				const lng = $this.data('lng');
				const pin = $this.data('pin');

				const pinMarker = {
					url: pin,
					size: new google.maps.Size(63, 90),
					scaledSize: new google.maps.Size(63, 90)
				}

				const position = new google.maps.LatLng(lat, lng);

				bounds.extend(position);

				const marker = new google.maps.Marker({
					position,
					map,
					icon: pinMarker
				});
			});

			map.fitBounds(bounds);
		});
	}
};

export default function mapsUI() {
	initMap();
}
